<!-- DepositStatsCard.vue -->
<template>
  <b-card
    title="PIX"
    :sub-title="$t(_id.status)"
    header-bg-variant="dark"
    header-text-variant="white"
  >
    <b-card-text>
      <b-row>
        <b-col>Valor solicitado:</b-col>
        <b-col>{{ totalValueUSD }}</b-col>
      </b-row>
      <b-row>
        <b-col>Valor com desconto:</b-col>
        <b-col>{{ totalValueBRL }}</b-col>
      </b-row>
      <b-row>
        <b-col>Contagem:</b-col>
        <b-col>{{ count }}</b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    _id: Object,
    totalValueUSD: Number,
    totalValueBRL: Number,
    count: Number,
  },
};
</script>
