import { render, staticRenderFns } from "./DepositsListPixApproveReject.vue?vue&type=template&id=17fb0772&scoped=true&"
import script from "./DepositsListPixApproveReject.vue?vue&type=script&lang=js&"
export * from "./DepositsListPixApproveReject.vue?vue&type=script&lang=js&"
import style0 from "./DepositsListPixApproveReject.vue?vue&type=style&index=0&id=17fb0772&lang=scss&scoped=true&"
import style1 from "./DepositsListPixApproveReject.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fb0772",
  null
  
)

export default component.exports